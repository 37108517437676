import axios from 'axios';
import { SERVICE_URL } from './config';
import { CreateTrialUserDTO, ListTrialUsersResponseDTO, TrialUserDTO, UpdateTrialUserDTO } from '@/types/TrialUser';
import { Page } from '@/types/Page';
import { SearchParams } from '@/types/config';
import { Status } from '@/enums/Status';

export const createTrialUser = async (input: CreateTrialUserDTO): Promise<TrialUserDTO> => {
  const resp = await axios.post<TrialUserDTO>(`${SERVICE_URL}/trial/user`, input);
  return resp.data;
};

export const getTrialUsers = async (
  page: Page,
  searchParms: SearchParams | undefined,
  status: Status
): Promise<ListTrialUsersResponseDTO> => {
  const resp = await axios.put<ListTrialUsersResponseDTO>(
    `${SERVICE_URL}/trial/users`,
    {
      ...page,
      status,
      ...searchParms,
    }
  );
  return resp.data;
};

export const updateTrialUser = async (
  input: UpdateTrialUserDTO
): Promise<TrialUserDTO> => {
  const resp = await axios.put<TrialUserDTO>(
    `${SERVICE_URL}/trial/user/${input.id}`,
    input
  );
  return resp.data;
};
