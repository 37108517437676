export const FORM_LAYOUT_FLEX = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 6 },
};
export const MODAL_FORM_LAYOUT_FLEX = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 24 },
};
export const BasicInfo_Item_LAYOUT_FLEX = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 8 },
  xxl: { span: 6 },
};
export const Table_Expand_LAYOUT_FLEX = {
  md: { span: 8 },
};
export const FORM_LAB_WRAPPER_FLEX = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

export const FORM_VALIDATE_MESSAGES = {
  required: '${label} is required!',
  types: {
    number: '${label} is not a valid number!',
    email: '${label} is not a valid email!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

export type SelectOption = {
  label: string;
  value: string;
};

export type SearchData = {
  field: string;
  value: any;
};

export type SearchParams = {
  [key: string]: any;
};

export type SystemStatus = 'initialized' | 'initializationNeeded';
