import axios from 'axios';
import { SystemStatus } from '@/types/config';
import { CreateUserDTO, UserDTO, AuthResponseDTO } from '@/types/User';
import { SERVICE_URL } from './config';
import { RegisterData } from '@/pages/Login';

export const login = async (
  username: string,
  password: string
): Promise<AuthResponseDTO> => {
  const response = await axios.post<AuthResponseDTO>(
    `${SERVICE_URL}/auth/login`,
    {
      username,
      password,
    }
  );
  return response.data;
};

export const fetchSystemStatus = async (): Promise<SystemStatus> => {
  const response = await axios.get<{ initialized: boolean }>(
    `${SERVICE_URL}/users/systemstatus`
  );
  const raw = response.data;
  return raw.initialized ? 'initialized' : 'initializationNeeded';
};

export const getCurrentUser = async (): Promise<UserDTO> => {
  const response = await axios.get<UserDTO>(`${SERVICE_URL}/auth/currentuser`);
  return response.data;
};

export const initUser = async (input: RegisterData) => {
  const response = await axios.post<{ status: string }>(
    `${SERVICE_URL}/users/init`,
    input
  );
  return response.data.status;
};

export const getUserList = async (): Promise<UserDTO[]> => {
  const resp = await axios.get<UserDTO[]>(`${SERVICE_URL}/users`);
  return resp.data;
};

export const createUser = async (input: CreateUserDTO): Promise<UserDTO> => {
  const resp = await axios.post<UserDTO>(`${SERVICE_URL}/users`, input);
  return resp.data;
};

export const updateUser = async (input: UserDTO): Promise<UserDTO> => {
  const resp = await axios.put<UserDTO>(
    `${SERVICE_URL}/users/${input.id}`,
    input
  );
  return resp.data;
};

export const deleteUser = async (userId: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${SERVICE_URL}/users/${userId}`)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};
