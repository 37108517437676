import { SERVICE_URL } from "@/services/config";

export const qlikLogin = async (username: string) => {
  const tokenRes = await (await getJWTToken(`${SERVICE_URL}/trial/token`, { username })).json();
  const { token, tenant, region, redirectUrl, webintegrationid } = tokenRes;
  const loggedIn = await checkLoggedIn(tenant, region, webintegrationid);
  if (loggedIn.status !== 200) {
    const loginRes = await jwtLogin(tenant, region, webintegrationid, token);
    console.log(loginRes);
    if (loginRes.status != 200) {
      const message = 'Something went wrong while logging in.';
      alert(message);
      throw new Error(message);
    }
    const recheckLoggedIn = await checkLoggedIn(tenant, region, webintegrationid);
    if (recheckLoggedIn.status !== 200) {
      const message = 'Third-party cookies are not enabled in your browser settings and/or browser mode.';
      alert(message);
      throw new Error(message);
    }
  }
  console.log('Logged in!');
  window.open(redirectUrl, '_self');
};

export const checkLoggedIn = async (tenant: string, region: string, webintegrationid: string) => {
  return await fetch(`https://${tenant}.${region}.qlikcloud.com/api/v1/users/me`, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      'qlik-web-integration-id': webintegrationid,
    },
  });
};

export const getJWTToken = async (jwtEndpoint: string, payload: { username: string }) => {
  return await fetch(jwtEndpoint, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
};

export const jwtLogin = async (tenant: string, region: string, webintegrationid: string, token: string) => {
  const authHeader = `Bearer ${token}`;
  return await fetch(`https://${tenant}.${region}.qlikcloud.com/login/jwt-session?qlik-web-integration-id=${webintegrationid}`, {
    credentials: 'include',
    mode: 'cors',
    method: 'POST',
    headers: {
      Authorization: authHeader,
      'qlik-web-integration-id': webintegrationid,
    },
  });
};