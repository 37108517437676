import React, { FunctionComponent, useEffect, useState } from 'react';
import { Form, Input, Button, notification, Typography, message } from 'antd';
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import './index.css';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useHistory } from 'react-router-dom';
import { fetchSystemStatus, initUser, login } from '@/services/AuthService';
import { FORM_VALIDATE_MESSAGES, SystemStatus } from '@/types/config';

const { Text } = Typography;

type LoginProps = {
  onLogin: () => Promise<void>;
};

type LoginData = {
  username: string;
  password: string;
};

export type RegisterData = {
  username: string;
  password: string;
  confirmPassword: string;
  fullname: string;
  description: string;
};

export const Login: FunctionComponent<LoginProps> = ({ onLogin }) => {
  const size = useWindowSize();
  const history = useHistory();
  const [systemStatus, setSystemStatus] = useState<SystemStatus>('initialized');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getSystemStatus();
  }, []);

  const getSystemStatus = async () => {
    try {
      const status = await fetchSystemStatus();
      setSystemStatus(status);
    } catch (e) {
      setSystemStatus('initialized');
    }
  };

  const onRegister = async (values: RegisterData) => {
    setLoading(true);
    try {
      const createStatus = await initUser(values);
      if (createStatus === 'adminExist') {
        notification.info({
          message: 'Admin already exist',
          description: 'Please login and use License with admin user!',
        });
      }
      setSystemStatus('initialized');
    } catch (e) {
      notification.error({
        message: 'Create admin failed',
        description: 'Please try again!',
      });
      setSystemStatus('initializationNeeded');
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values: LoginData) => {
    const { username, password } = values;
    setLoading(true);
    try {
      const data = await login(username, password);
      localStorage.setItem('token', data.token);
      await onLogin();
      history.push('/');
    } catch (error) {
      message.error(`Login Failed: ${(error as any).data.message}`);
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div className="login-container" style={{ height: size.height || 600 }}>
      <div className="login-wrapper">
        <h1 className="text-logo">USER PORTAL</h1>
        {systemStatus === 'initialized' && (
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{}}
            onFinish={onFinish}
            {...FORM_VALIDATE_MESSAGES}
          >
            <Form.Item name="username" rules={[{ required: true }, { type: 'email' }]}>
              <Input prefix={<UserOutlined />} placeholder="Username" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true }]}>
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
                iconRender={(visible) => {
                  if (visible) {
                    return <EyeTwoTone />;
                  } else {
                    return <EyeInvisibleOutlined />;
                  }
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        )}
        {systemStatus === 'initializationNeeded' && (
          <Form
            name="register-user"
            className="login-form"
            initialValues={{ username: 'rootadmin@extendbi.com' }}
            onFinish={onRegister}
            {...FORM_VALIDATE_MESSAGES}
          >
            <Form.Item>
              <Text type="danger">
                Initialize admin user to start using ExtendBI user protal
              </Text>
            </Form.Item>

            <Form.Item
              name="username"
              rules={[
                { required: true, message: 'Please input your Username!' },
              ]}
            >
              <Input
                disabled
                prefix={<UserOutlined />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true },
                ({}) => ({
                  validator(_, value) {
                    if (!value || value.length >= 8) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Please use at least 8 characters as password!')
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
                iconRender={(visible) => {
                  if (visible) {
                    return <EyeTwoTone />;
                  } else {
                    return <EyeInvisibleOutlined />;
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder="Confirm Password"
                iconRender={(visible) => {
                  if (visible) {
                    return <EyeTwoTone />;
                  } else {
                    return <EyeInvisibleOutlined />;
                  }
                }}
              />
            </Form.Item>
            <Form.Item name="fullname" rules={[{ required: true }]}>
              <Input prefix={<IdcardOutlined />} placeholder="Fullname" />
            </Form.Item>
            <Form.Item name="description">
              <Input.TextArea
                showCount
                placeholder="Description"
                maxLength={120}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Initialize Admin
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};
