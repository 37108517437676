import React, { useEffect, useState } from 'react';
import { StepProps, Steps } from 'antd';
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import './index.css';

export type Step = string | 'Done';

type StepSpinProps = {
  show: boolean;
  steps: Step[];
  error?: string;
  onClose: () => void;
};

export const StepSpin: React.FC<StepSpinProps> = ({show, steps, error, onClose, children}) => {
  const [stepItems, setStepItems] = useState<StepProps[]>();

  useEffect(() => {
    setStepItems(steps.map((step, i) => {
      const totalIndex = steps.length - 1;
      if (i < totalIndex) {
        return {
          title: step,
          status: 'finish',
          icon: <CheckCircleOutlined />
        }
      } else if (i === totalIndex) {
        if (error) {
          return {
            title: step,
            status: 'error',
          }
        }
        return {
          title: step,
          status: 'process',
          icon: <LoadingOutlined />
        }
      } else {
        return {
          title: step,
          status: 'wait',
        }
      }
    }));
  }, [steps, error]);

  return (
    <>
      {show && (
        <div className='stepSpinWrapper'>
          {/* <Spin spinning={true} className='stepSpinSpin' indicator={antIcon}></Spin> */}
          <Steps
            className='stepSpinSteps'
            direction="vertical"
            size="small"
            current={steps.length - 1}
            items={stepItems}
          />
          {error && (
            <>
              <p className='stepSpinErrorText'>{error}</p>
              <CloseOutlined onClick={onClose} className='stepSpinCloseBtn'/>
            </>
          )}
        </div>
      )}
      {children}
    </>
  )
}