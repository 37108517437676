import React, { useState } from 'react';
import './index.css';
import { Row, Col, Form, Input } from 'antd';
import { CreateTrialUserDTO } from '@/types/TrialUser';
import { createTrialUser } from '@/services/TrialService';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { checkLoggedIn, getJWTToken, jwtLogin } from '@/utils/qlikUtils';
import { Step, StepSpin } from './components/StepSpin';
import { SERVICE_URL } from '@/services/config';

type TrialProps = {};

export const Trial: React.FC<TrialProps> = ({ }) => {
  const [form] = Form.useForm<CreateTrialUserDTO>();
  const [loading, setLoading] = useState<boolean>(false);
  const { xs } = useBreakpoint();
  const [steps, setSteps] = useState<Step[]>([]);
  const [error, setError] = useState<string>();

  const doTheStep = (step: Step) => {
    setSteps(curr => [
      ...curr,
      step
    ]);
  }

  const onFinish = async (values: CreateTrialUserDTO) => {
    try {
      setLoading(true);
      doTheStep('Checking user ...');
      await createTrialUser(values).then(() => {
        doTheStep('New user, initializing resource ...');
      }).catch((err) => {
        const errMsg = err.data.message;
        if (!errMsg.includes('already exist')) throw new Error(err.data.message);
        doTheStep('User has already exist, welcome back!');
      });
      doTheStep('Getting access for you ...');
      const tokenRes = await (await getJWTToken(`${SERVICE_URL}/trial/token`, { username: values.username })).json();
      const { token, tenant, region, redirectUrl, webintegrationid } = tokenRes;

      doTheStep('Checking login status ...');
      const loggedIn = await checkLoggedIn(tenant, region, webintegrationid);

      if (loggedIn.status !== 200) {
        doTheStep('Logining to the platform ...');
        const loginRes = await jwtLogin(tenant, region, webintegrationid, token);
        if (loginRes.status != 200) {
          const message = 'Something went wrong while logging in.';
          throw new Error(message);
        }
        const recheckLoggedIn = await checkLoggedIn(tenant, region, webintegrationid);
        if (recheckLoggedIn.status !== 200) {
          const message = 'Third-party cookies are not enabled in your browser settings and/or browser mode.';
          throw new Error(message);
        }
      }
      doTheStep('Well Done!');
      doTheStep('Refreshing go to platform ...');
      setTimeout(() => {
        window.open(redirectUrl, '_self');
      }, 1000);
    } catch (error: any) {
      if (error?.message) {
        setError(error?.message);
      } else {
        setError(error?.data?.message);
      }
    }
  };

  return (
    <div className='trialContainer'>
      <div className='trialWrapper'>
        <Row className='trialContainerRow' style={{ height: xs ? '100%' : 'auto' }}>
          <Col xs={24} sm={12}>
            <div className='trialTitleWrapper'>
              <div className='trialTitleLarge'>
                Take ExtendBI™ for a Test Drive Today!
              </div>
              <div className='trialTitleRegular'>
                Easy to use Qlik extensions. No need to call IT.
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='trialForm'>
              <StepSpin
                show={loading}
                steps={steps}
                error={error}
                onClose={() => {
                  setLoading(false);
                  setError(undefined);
                  setSteps([]);
                }}
              >
                <Form
                  layout="vertical"
                  autoComplete="off"
                  onFinish={onFinish}
                  hideRequiredMark={true}
                >
                  <div className='trialFormTitle'>Start your free trial now.</div>
                  <Form.Item
                    name="fullname"
                    label="FULL NAME"
                    className='trialFormItem'
                    rules={[{ required: true, message: 'Full name is required!' }]}
                  >
                    <Input className='trialFormItemInput' />
                  </Form.Item>
                  <Form.Item
                    name="username"
                    label="EMAIL"
                    className='trialFormItem'
                    rules={[
                      { required: true, message: 'Email is required!' },
                      { type: 'email', message: 'Not a valid email!' }
                    ]}
                  >
                    <Input className='trialFormItemInput' />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label="COMPANY"
                    className='trialFormItem'
                  >
                    <Input className='trialFormItemInput' />
                  </Form.Item>
                  <div style={{ marginTop: 25 }}>
                      <input
                        type='submit'
                        value={'Get Started'}
                        className='trialSubmitBtn'
                        onClick={() => form.submit()}
                      />
                  </div>
                </Form>
              </StepSpin>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
