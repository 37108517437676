import { UserContext } from '@/App';
import {
  AppstoreOutlined,
  CodeOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Layout, Menu, Spin } from 'antd';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import './index.css';
import LOGO from '@/images/logo192.png';
import { Role } from '@/enums/Role';

const { Header, Content, Sider } = Layout;

const Tenant = lazy((): any => import('@/pages/Tenant'));
const License = lazy((): any => import('@/pages/License'));
const TrialUser = lazy((): any => import('@/pages/TrialUser'));
const LicenseRecord = lazy((): any => import('@/pages/License'));
const ProductUser = lazy((): any => import('@/pages/ProductUser'));
const Usage = lazy((): any => import('@/pages/Usage'));
const Product = lazy((): any => import('@/pages/Product'));
const User = lazy((): any => import('@/pages/User'));
const NoFoundPage = lazy((): any => import('@/pages/404'));
const NoAccessPage = lazy((): any => import('@/pages/403'));

type HomeProps = {};

type MenuOptions = {
  text: string;
  icon: any;
}

type RouteType = {
  name: string;
  path: string;
  component: React.LazyExoticComponent<React.ComponentType<any>>;
  roles: Role[];
  menuOptions?: MenuOptions;
}

export const Home: React.FC<HomeProps> = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const userInfo = useContext(UserContext);

  const routes: RouteType[] = [
    {
      name: 'license',
      path: '/license',
      component: License,
      menuOptions: {
        text: 'License',
        icon: <CodeOutlined />,
      },
      roles: [Role.SYSTEM_ADMIN],
    },
    {
      name: 'tenant',
      path: '/tenant',
      component: Tenant,
      menuOptions: {
        text: 'Tenant',
        icon: <TeamOutlined />,
      },
      roles: [Role.SYSTEM_ADMIN, Role.MULTI_TENANTS_ADMIN],
    },
    {
      name: 'license-records',
      path: '/license-records/:tenantID',
      component: LicenseRecord,
      roles: [Role.SYSTEM_ADMIN],
    },
    {
      name: 'product-users',
      path: '/product-users',
      component: ProductUser,
      menuOptions: {
        text: 'Product User',
        icon: <TeamOutlined />,
      },
      roles: [Role.TENANT_ADMIN],
    },
    {
      name: 'product-users',
      path: '/product-users/:tenantID',
      component: ProductUser,
      roles: [Role.SYSTEM_ADMIN, Role.MULTI_TENANTS_ADMIN],
    },
    {
      name: 'usage',
      path: '/usage/:tenantID',
      component: Usage,
      roles: [Role.SYSTEM_ADMIN, Role.MULTI_TENANTS_ADMIN],
    },
    {
      name: 'product',
      path: '/product',
      component: Product,
      menuOptions: {
        text: 'Product',
        icon: <AppstoreOutlined />,
      },
      roles: [Role.SYSTEM_ADMIN],
    },
    {
      name: 'users',
      path: '/users',
      component: User,
      menuOptions: {
        text: 'User',
        icon: <UserOutlined />,
      },
      roles: [Role.SYSTEM_ADMIN, Role.MULTI_TENANTS_ADMIN],
    },
    {
      name: 'trial-users',
      path: '/trial-users',
      component: TrialUser,
      menuOptions: {
        text: 'Trial User',
        icon: <FieldTimeOutlined />,
      },
      roles: [Role.SYSTEM_ADMIN],
    },
  ]

  const menuItems = routes.filter(route => route.roles.includes(userInfo?.role!) && !!route.menuOptions);
  const defaultRoutePath = menuItems && menuItems.length > 0 && menuItems[0].path || '';

  useEffect(() => { }, []);

  const trimPath = () => path.trim() === '/' ? '' : path.trim();

  const logout = () => {
    window.localStorage.removeItem('token');
    history.push('/login');
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={logout}>
        <div className="user-dropdown-item">
          <LogoutOutlined />
          <span className="user-dropdown-text">Logout</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  if (!userInfo || !userInfo.role) return <Spin spinning={true}></Spin>;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="header-ui">
        <div className='sidebar-logo-container'>
          <img src={LOGO} alt="logo" className='sidebar-logo-img' />
          <h1 className="sidebar-logo-text">ExtendBI User Protal</h1>
        </div>
        <Dropdown overlay={menu} trigger={['click']}>
          <div
            style={{
              color: '#333',
              cursor: 'pointer',
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar
              style={{ backgroundColor: '#1890ff', marginRight: '5px' }}
              icon={<UserOutlined />}
            ></Avatar>
            <span>{userInfo?.fullname}</span>
          </div>
        </Dropdown>
      </Header>
      <Layout>
        <Sider
          className="aside"
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
        >
          <Menu mode="inline" selectedKeys={[pathname]}>
            {menuItems.map(route => {
              const options = route.menuOptions;
              const routePath = trimPath() + route.path;
              return (
                <Menu.Item key={route.path} icon={options?.icon}>
                  <Link to={`${routePath}`}>{options?.text}</Link>
                </Menu.Item>
              )
            })}
          </Menu>
        </Sider>
        <Layout>
          <div className="layout-content">
            <Content>
              <Suspense fallback={<Spin size="large" />}>
                <Switch>
                  <Route
                    exact
                    path={`${path}`}
                    render={() => <Redirect to={`${trimPath()}${defaultRoutePath}`} />}
                  />
                  {routes.map((route: RouteType) => {
                    const routePath = trimPath() + route.path;
                    if (!route.roles.includes(userInfo?.role!)) {
                      return (
                        <Route key={route.name} exact path={`${routePath}`}
                          render={() => <Redirect to={`${trimPath()}/403`} />}
                        />
                      )
                    }
                    const { component: LazyComponent } = route;
                    return (
                      <Route key={route.name} exact path={`${routePath}`}>
                        <LazyComponent />
                      </Route>
                    )
                  })}
                  <Route exact path={`${trimPath()}/403`}>
                    <NoAccessPage />
                  </Route>
                  <Route exact path={`${trimPath()}/404`}>
                    <NoFoundPage />
                  </Route>
                  <Route render={() => <Redirect to={`${trimPath()}/404`} />} />
                </Switch>
              </Suspense>
            </Content>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};
